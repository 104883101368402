import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { useAuth } from '../../../hooks/auth';
import { useTheme } from '../../../hooks/theme';

import api from '../../../services/api';

import autoAnimate from '@formkit/auto-animate';
import ReactPlayer from 'react-player';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import {
  CaretLeft,
  Eye,
  House,
  MonitorPlay,
  Student,
  Timer,
  Trash,
} from 'phosphor-react';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import Breadcrumbs from '../../../components/Breadcrumbs';
import Loader from '../../../components/Loader';
import TooltipAdapter from '../../../components/TooltipAdapter';
import Hr from '../../../components/Hr';
import CardMaterial from '../../../components/CardMaterial';
import AvatarProgress from '../../../components/AvatarProgress';
import ToggleLikeAndDeslike, {
  IVoteType,
} from '../../../components/ToggleLikeAndDeslike';
import ExerciseModule from '../../../components/ExerciseModule';
import LoaderSpinner from '../../../components/LoaderSpinner';
import VideoTumbnail from '../../../components/VideoTumbnail';
import FloatWallButtons from '../../../components/FloatWallButtons';
import ViewedLessonButton from './ViewedLessonButton';

import {
  FullWidthContainer,
  FullWidthAside,
  FullWidthMain,
  FullWidthMainContent,
  PlayerContainer,
  ItemList,
  ModuleITitle,
  RatingVideoContainer,
  CommentsContainer,
  Center,
  Commentmain,
  MyCommentsContainer,
  TrashIcon,
  MaterialsContainer,
  BreadcrumbsContainer,
  ExerciseButtonContainer,
  AccordionDetailsList,
  StudyPlanName,
  LoaderLessonsContainer,
  LiteVideo,
} from './styles';

import { maxWidthContent } from '../../../utils/maxWidthContent';

import { IClusterProps } from '../../../interfaces/Cluster';
import { ILessonProps } from '../../../interfaces/Lesson';
import PlayerAdapter from '../../../components/PlayerAdapter';
import MaterialFloatingBadge from '../../../components/MaterialFloatingBadge';

export interface ICommentsProps {
  id: string;
  lesson_id: string;
  user_id: string;
  video_time?: null;
  content: string;
  created_at: string;
  updated_at: string;
}

export interface IStudyPlanProps {
  id: string;
  title: string;
  slug: string;
  description: string;
  created_at: string;
  updated_at: string;
  material: string;
  material_url: string;
  totalDuration: string;
  countLessons: number;
  thumbnail_url: string;
  clusters: ClustersEntity[];
}

export interface ClustersEntity {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
  slug: string;
  order: number;
  created_at: string;
  updated_at: string;
  lessons: LessonsEntity[];
  thumbnail_url: string;
}

export interface LessonsEntity {
  id: string;
  cluster_id: string;
  title: string;
  description: string;
  order: number;
  duration: string;
  views: number;
  slug: string;
  video: string;
  video_provider: string;
  audio?: null;
  upvotes: number;
  downvotes: number;
  created_at: string;
  updated_at: string;
  attachments?: AttachmentsEntity[];
  vote_balance: number;
  duration_formatted: string;
  video_url: string;
}
export interface AttachmentsEntity {
  id: string;
  lesson_id: string;
  name: string;
  type: string;
  file: string;
  created_at: string;
  updated_at: string;
  file_url: string;
}

interface IInfoProps {
  views: number;
  notes: ICommentsProps[];
  user_vote: IVoteType | null;
  has_exercises: boolean;
}

const PlanoDeEstudosAula: React.FC = () => {
  const { user } = useAuth();
  const { slug, clusterSlug, lessonSlug } = useParams<{
    slug: string;
    clusterSlug?: string;
    lessonSlug?: string;
  }>();
  const { theme } = useTheme();

  // Refs
  const parentCommentsList = useRef<HTMLDivElement>(null);
  const parentListLessons = useRef<HTMLUListElement>(null);

  // Loading
  const [loading, setLoading] = useState(true);

  const [isExercise, setIsExercise] = useState(false);

  // Data
  const [clusterData, setClusterData] = useState<
    Omit<IClusterProps, 'lessons'>[]
  >([]);
  const [studyPLanData, setStudyPLanData] = useState<IStudyPlanProps>(
    {} as IStudyPlanProps,
  );

  const [currentInfos, setCurrentInfos] = useState<IInfoProps>(
    {} as IInfoProps,
  );

  const [lessons, setLessons] = useState<{ [key: string]: ILessonProps[] }>({});

  // States
  const [comment, setComment] = useState('');
  const [currentLesson, setCurrentLesson] = useState<
    (ILessonProps & { planOrder: number; cluster: IClusterProps }) | null
  >(null);

  // Player
  const playerRef = useRef<ReactPlayer>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showThumbnail, setShowThumbnail] = useState(true);
  const [timeLeft, setTimeLeft] = useState(30);

  const handleLessonCompleted = useCallback(
    async (lesson_id: string, cluster_slug: string) => {
      try {
        const clusterLessons = lessons[cluster_slug];
        const findLesson = clusterLessons.find(
          lesson => lesson.id === lesson_id,
        );

        if (findLesson) {
          const response = await api.post(
            `/clusters/lessons/${lesson_id}/progress`,
            {
              video_time:
                Number(findLesson.viewed) >= Number(findLesson.duration)
                  ? 0
                  : Number(Math.ceil(findLesson.duration)), // arredondar para cima
              is_study_plan: true,
            },
          );

          setLessons(oldLessons => ({
            ...oldLessons,
            [cluster_slug]: oldLessons[cluster_slug].map(lesson => {
              if (lesson.id === lesson_id) {
                return {
                  ...lesson,
                  viewed: Number(response.data.video_time),
                };
              }

              return lesson;
            }),
          }));
        }
      } catch (error) {
        console.log(error);
        toast.error('Ops! Algo deu errado.', {
          position: 'bottom-center',
          duration: 6000,
        });
      }
    },
    [lessons],
  );

  useEffect(() => {
    const TIME_LEFT = 20;

    const interval = setInterval(() => {
      if (!isPlaying) {
        return;
      }

      setTimeLeft(oldTimeLeft => {
        if (oldTimeLeft === 0) {
          return TIME_LEFT; // reset time count 20 seconds
        }

        return oldTimeLeft - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isPlaying]);

  const handleSaveCurrentPostion = useCallback(
    async (position: number) => {
      try {
        if (!currentLesson) {
          return;
        }

        const response = await api.post(
          `/clusters/lessons/${currentLesson.id}/progress`,
          {
            video_time: Math.round(position),
            is_study_plan: true,
          },
        );

        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    },
    [currentLesson],
  );

  const onPlay = useCallback(() => {
    console.log('onPlay');
    setIsPlaying(true);
    setShowThumbnail(false);
  }, []);

  const onPause = useCallback(() => {
    console.log('onPause');

    setIsPlaying(false);
  }, []);

  const onEnded = useCallback(
    async (lesson_id: string, cluster_slug: string) => {
      console.log('onCompleted');
      setIsPlaying(false);

      handleLessonCompleted(lesson_id, cluster_slug);
    },
    [handleLessonCompleted],
  );

  const onReady = useCallback(
    (playerRef: any) => {
      console.log('onReady');

      if (playerRef.current) {
        if (!currentLesson) {
          return;
        }

        if (currentLesson.viewed !== 0) {
          playerRef.current.seekTo(currentLesson.viewed);
          return;
        }
      }
    },
    [currentLesson],
  );

  const onProgress = useCallback(
    (
      played: number,
      playedSeconds: number,
      loaded: number,
      loadedSeconds: number,
    ) => {
      console.log('onProgress');
      console.log({ played, playedSeconds, loaded, loadedSeconds });

      if (timeLeft === 0) {
        handleSaveCurrentPostion(playedSeconds); // send to backend
      }
    },
    [handleSaveCurrentPostion, timeLeft],
  );

  // END Player

  useLayoutEffect(() => {
    const mainContentSection: any = document.querySelector('#main-scroll');
    const mainContentCenter: any = document.querySelector('#content-center');
    const mainFooter: any = document.querySelector('#main-footer-container');

    if (mainContentSection && mainContentCenter && mainFooter) {
      mainContentSection.style.padding = '0px';
      mainContentCenter.style.maxWidth = '100%';
      mainFooter.style.display = 'none';
    }

    return () => {
      if (mainContentSection && mainContentCenter) {
        mainContentSection.style.padding = '2.35rem 1rem 2rem 2rem';
        mainContentCenter.style.maxWidth = maxWidthContent;
        mainFooter.style.display = 'block';
      }
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get(`/study-plans/${slug}`);
        console.log(response.data);

        setClusterData(response.data?.clusters);
        setStudyPLanData(response.data);
      } catch (error) {
        setLoading(false);
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [slug]);

  useEffect(() => {
    (async () => {
      try {
        if (currentLesson?.id) {
          const response = await api.get<IInfoProps>(
            `/clusters/lessons/${currentLesson.id}/infos`,
          );

          setCurrentInfos(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [currentLesson]);

  useEffect(() => {
    parentCommentsList.current && autoAnimate(parentCommentsList.current);
    parentListLessons.current && autoAnimate(parentListLessons.current);
  }, [parentCommentsList, parentListLessons]);

  const handleChangeType = useCallback((value: boolean) => {
    setIsExercise(value);
    const mainScroll = document.querySelector('#main-scroll');

    if (mainScroll && value) {
      mainScroll.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  const handlePostComment = useCallback(async () => {
    try {
      if (!currentLesson) {
        return;
      }

      const response = await api.post(
        `/clusters/${currentLesson.cluster_id}/lessons/${currentLesson.slug}/notes`,
        {
          video_time: null,
          content: comment,
        },
      );
      console.log(response.data);
      toast.success('Anotação criada com sucesso!', {
        position: 'bottom-right',
        duration: 6000,
        className: 'toast-samuquinha',
      });

      setCurrentInfos(oldCurrentInfos => ({
        ...oldCurrentInfos,
        notes: [response.data, ...oldCurrentInfos.notes],
      }));

      setComment('');
    } catch (error) {
      console.log(error);
      toast.error('Algo deu errado ao criar sua anotação!', {
        position: 'bottom-center',
        duration: 6000,
      });
    }
  }, [comment, currentLesson]);

  const handleDeleteComment = useCallback(
    (id: string) => {
      if (window.confirm('Deseja excluir essa anotação?')) {
        (async () => {
          try {
            if (!currentLesson) {
              return;
            }

            const response = await api.delete(
              `/clusters/lessons/${currentLesson.slug}/notes/${id}`,
            );
            console.log(response.data);

            toast.error('Anotação excluída com sucesso!', {
              position: 'bottom-right',
              duration: 6000,
              className: 'toast-samuquinha',
            });

            setCurrentInfos(oldCurrentInfos => ({
              ...oldCurrentInfos,
              notes: oldCurrentInfos.notes.filter(note => note.id !== id),
            }));
          } catch (error) {
            console.log(error);
            toast.error('Algo deu errado ao excluir sua anotação!', {
              position: 'bottom-center',
              duration: 6000,
            });
          }
        })();
      }
    },
    [currentLesson],
  );

  const handleUserVote = useCallback(
    async (lesson_id: string, voteType: IVoteType, cluster_slug: string) => {
      try {
        const response = await api.post(`/clusters/lessons/${lesson_id}/vote`, {
          type: voteType,
        });

        const { upvotes, downvotes, user_vote } = response.data;

        setCurrentInfos(oldCurrentInfos => ({
          ...oldCurrentInfos,
          user_vote,
        }));

        const clusterLessons = lessons[cluster_slug];

        setLessons(oldLessons => ({
          ...oldLessons,
          [cluster_slug]: clusterLessons.map(lesson => {
            if (lesson.id === lesson_id) {
              return {
                ...lesson,
                upvotes,
                downvotes,
                user_vote,
              };
            }

            return lesson;
          }),
        }));
      } catch (error) {
        console.log(error);
      }
    },
    [lessons],
  );

  const handleLoadLessons = useCallback(
    async (cluster_slug: string) => {
      try {
        const clusterLessons = lessons[cluster_slug];

        if (clusterLessons) {
          console.log('já carregado');
          return;
        }

        const response = await api.get(`/study-plans/${slug}/items`, {
          params: {
            cluster_slug: cluster_slug,
          },
        });
        console.log(`Lessons do Cluster: ${cluster_slug}`, response.data);

        setLessons(oldLessons => ({
          ...oldLessons,
          [cluster_slug]: response.data,
        }));
      } catch (error) {
        console.log(error);
      }
    },
    [lessons, slug],
  );

  useEffect(() => {
    if (clusterSlug === '!') {
      const firstCluster = clusterData.at(0);

      if (firstCluster) {
        handleLoadLessons(firstCluster.slug);

        const clusterLesson = lessons[firstCluster.slug];

        if (clusterLesson) {
          const firstLesson = clusterLesson.at(0);

          if (firstLesson) {
            setCurrentLesson({
              ...firstLesson,
              planOrder: 1,
              cluster: {
                ...firstCluster,
                lessons: clusterLesson,
              },
            });
            handleChangeType(false);
            setIsPlaying(false);
            setShowThumbnail(true);
            return;
          }
        }
      }
    } else {
      const cluster = clusterData.find(cluster => cluster.slug === clusterSlug);

      if (cluster) {
        handleLoadLessons(cluster.slug);

        const clusterLesson = lessons[cluster.slug];

        if (clusterLesson) {
          const lesson = clusterLesson.find(
            lesson => lesson.slug === lessonSlug,
          );

          if (lesson) {
            setCurrentLesson({
              ...lesson,
              planOrder: 1,
              cluster: {
                ...cluster,
                lessons: clusterLesson,
              },
            });
            handleChangeType(false);
            setIsPlaying(false);
            setShowThumbnail(true);
            return;
          }
        }
      }
    }
  }, [
    clusterData,
    clusterSlug,
    handleChangeType,
    handleLoadLessons,
    lessonSlug,
    lessons,
  ]);

  return (
    <>
      <Loader
        isVisible={
          loading ||
          Object.keys(lessons).length === 0 ||
          clusterData.length === 0
        }
      />
      <FloatWallButtons to={`/plano-de-estudos?change=true`} />

      <FullWidthContainer>
        <FullWidthMainContent>
          <FullWidthAside>
            <main>
              {clusterData.map(cluster => (
                <AccordionDetailsList
                  key={cluster.id}
                  id={`accordion-video-titles`}
                  open={cluster.slug === currentLesson?.cluster.slug}
                >
                  <summary onClick={() => handleLoadLessons(cluster.slug)}>
                    <ModuleITitle key={cluster.id}>
                      <aside>
                        <img
                          src={cluster.thumbnail_url}
                          alt={cluster.title}
                          loading="lazy"
                        />
                      </aside>
                      <main>
                        <h5>Módulo {cluster.order}</h5>
                        <h2>{cluster.title}</h2>
                      </main>
                    </ModuleITitle>
                  </summary>
                  <ItemList>
                    <ul ref={parentListLessons}>
                      {lessons[cluster.slug]?.length > 0 ? (
                        lessons[cluster.slug]?.map((lesson: ILessonProps) => (
                          <li key={lesson.id}>
                            <TooltipAdapter
                              alt="Marcar como concluída"
                              place="top"
                            >
                              <ViewedLessonButton
                                isViewed={
                                  Number(lesson.viewed) >=
                                  Number(lesson.duration)
                                }
                                onClick={() =>
                                  handleLessonCompleted(lesson.id, cluster.slug)
                                }
                              />
                            </TooltipAdapter>
                            <Link
                              to={`/plano-de-estudos/${slug}/${cluster.slug}/${lesson.slug}`}
                              onClick={() => {
                                const mainScroll =
                                  document.querySelector('#main-scroll');
                                const lessonTitle =
                                  document.querySelector('#lesson-title');

                                const headerHeight = 80 + 4;

                                if (
                                  mainScroll &&
                                  lessonTitle &&
                                  window.innerWidth < 1024
                                ) {
                                  setTimeout(() => {
                                    mainScroll.scrollTo({
                                      top:
                                        lessonTitle.getBoundingClientRect()
                                          .top - headerHeight,
                                      behavior: 'smooth',
                                    });
                                  }, 1); // 1ms delay to rack the scrollToTop hook on the useEffect Layout
                                }
                              }}
                              className={
                                currentLesson?.id === lesson.id
                                  ? 'selected'
                                  : ''
                              }
                            >
                              {lesson.title}
                            </Link>
                          </li>
                        ))
                      ) : (
                        <LoaderLessonsContainer>
                          <LoaderSpinner background="transparent" />
                        </LoaderLessonsContainer>
                      )}
                    </ul>
                  </ItemList>
                </AccordionDetailsList>
              ))}
            </main>
          </FullWidthAside>

          {!isExercise ? (
            <FullWidthMain>
              <StudyPlanName
                style={
                  theme === 'light' ? { borderLeft: '1px solid #e4f6f2' } : {}
                }
              >
                <p>
                  <Student weight="duotone" size={25} />
                  Você está no plano de estudos:{' '}
                  <Link to="/plano-de-estudos?change=true">
                    {user.study_plan?.title}
                  </Link>
                </p>
              </StudyPlanName>
              <Center>
                <header>
                  <MaterialFloatingBadge
                    onClick={() =>
                      window.open(`${studyPLanData?.material_url}`)
                    }
                  />
                  <div>
                    <Breadcrumbs
                      icon={House}
                      separator="/"
                      route={[
                        {
                          title: 'Plano de Estudos',
                          link: '/plano-de-estudos?change=true',
                        },
                        {
                          title: `${currentLesson?.cluster?.title}`,
                          link: `${currentLesson?.slug}`,
                        },
                      ]}
                    />
                  </div>
                  <h1 id="lesson-title">{currentLesson?.title}</h1>
                  <p>{currentLesson?.description}</p>
                </header>

                {showThumbnail ? (
                  <div
                    style={
                      currentInfos?.has_exercises
                        ? { borderRadius: '12px 12px 0 0', width: '100%' }
                        : {
                            borderRadius: '12px',
                            width: '100%',
                            marginBottom: '2rem',
                          }
                    }
                  >
                    <VideoTumbnail
                      videoTitle={currentLesson?.title ?? ''}
                      videoTumbnailUrl={
                        currentLesson?.video_provider === 'panda'
                          ? `https://vz-cb1dc143-4cf.tv.pandavideo.com.br/${currentLesson?.video}/thumbnail.jpg`
                          : currentLesson?.video_provider === 'youtube'
                          ? `https://img.youtube.com/vi/${currentLesson?.video}/maxresdefault.jpg`
                          : currentLesson?.thumbnail_url
                      }
                      percentView={
                        (Number(currentLesson?.viewed) * 100) /
                        Number(currentLesson?.duration)
                      }
                      borderRadius={
                        currentLesson?.has_exercises ? '12px 12px 0 0' : '12px'
                      }
                      onClick={onPlay}
                    />
                  </div>
                ) : (
                  <>
                    <PlayerContainer
                      style={
                        currentInfos?.has_exercises
                          ? { borderRadius: '12px 12px 0 0' }
                          : { borderRadius: '12px', marginBottom: '2rem' }
                      }
                    >
                      {currentLesson?.id && (
                        <PlayerAdapter
                          provider={currentLesson.video_provider}
                          video_url={currentLesson.video_url}
                          video={currentLesson.video}
                          onReady={onReady}
                          onPlay={onPlay}
                          onPause={onPause}
                          onEnded={() =>
                            currentLesson &&
                            onEnded(
                              currentLesson.id,
                              currentLesson.cluster.slug,
                            )
                          }
                          onProgress={onProgress}
                        />
                      )}
                    </PlayerContainer>
                  </>
                )}
                {currentInfos?.has_exercises && (
                  <ExerciseButtonContainer
                    onClick={() => handleChangeType(true)}
                  >
                    <p>
                      <img
                        src="https://samuel-cunha-api.s3.sa-east-1.amazonaws.com/site/fire.png"
                        alt=""
                      />
                      Exercícios de fixaçãos sobre a aula
                      <img
                        src="https://samuel-cunha-api.s3.sa-east-1.amazonaws.com/site/fire.png"
                        alt=""
                      />
                    </p>
                  </ExerciseButtonContainer>
                )}
                <LiteVideo>
                  O vídeo está travando?
                  <Link
                    to={`/video-lite/?title=${currentLesson?.title}&provider=${currentLesson?.video_provider}&videoUrl=${currentLesson?.video_url}`}
                  >
                    <MonitorPlay size={18} color="#ffffff" weight="duotone" />{' '}
                    Experimente o carregamento leve
                  </Link>
                </LiteVideo>
                <RatingVideoContainer style={{ zIndex: 0 }}>
                  <aside>
                    {currentLesson && (
                      <ToggleLikeAndDeslike
                        upvotes={currentLesson?.upvotes}
                        downvotes={currentLesson?.downvotes}
                        userVote={currentInfos?.user_vote ?? null}
                        onVote={voteType =>
                          handleUserVote(
                            currentLesson?.id,
                            voteType,
                            currentLesson?.cluster?.slug,
                          )
                        }
                      />
                    )}
                    <p>
                      <Timer size={20} weight="bold" />{' '}
                      {currentLesson?.duration_formatted}
                    </p>
                    <p>
                      <Eye size={20} weight="bold" /> {currentInfos.views}
                    </p>
                  </aside>
                  <TooltipAdapter alt="Marcar como concluída" place="top">
                    {currentLesson && (
                      <ViewedLessonButton
                        isViewed={
                          Number(currentLesson?.viewed) >=
                          Number(currentLesson?.duration)
                        }
                        onClick={() => {
                          handleLessonCompleted(
                            currentLesson?.id,
                            currentLesson?.cluster?.slug,
                          );
                        }}
                        label="Marcar como concluída"
                      />
                    )}
                  </TooltipAdapter>
                </RatingVideoContainer>
              </Center>

              <CommentsContainer>
                <Center>
                  {((currentLesson?.attachments &&
                    currentLesson?.attachments?.length > 0) ||
                    currentLesson?.cluster?.material_url) && (
                    <MaterialsContainer>
                      <h3>Materiais de apoio:</h3>

                      <main>
                        {/*
                        // Estava dando erro por algum motivo e percebi que não havia necessidade dessa verificação na página do plano de estudos
                        {currentLesson?.id ===
                            lessons[clusterData[0]?.slug][0]?.id && (
                            <CardMaterial
                              href={studyPLanData?.material_url}
                              title="Plano de estudo"
                              type="pdf"
                            />
                          )} */}
                        <CardMaterial
                          href={studyPLanData?.material_url}
                          title="Plano de estudo"
                          type="pdf"
                        />

                        {currentLesson?.cluster?.material_url && (
                          <CardMaterial
                            href={currentLesson?.cluster?.material_url}
                            title={currentLesson?.cluster?.title}
                            type="pdf"
                          />
                        )}
                        {currentLesson?.attachments?.map(
                          (attachment, index) => (
                            <CardMaterial
                              href={attachment.file_url}
                              key={index}
                              title={attachment.name}
                              type={attachment.type}
                            />
                          ),
                        )}
                      </main>
                    </MaterialsContainer>
                  )}

                  <header>
                    <h3>
                      Suas anotações sobre a aula ({currentInfos?.notes?.length}
                      )
                    </h3>
                  </header>
                  <Commentmain>
                    <aside>
                      <AvatarProgress
                        url={user.avatar_url}
                        percent={user.current_exp}
                        size={80}
                        badgeValue={user.level}
                      />
                    </aside>
                    <main>
                      <div>
                        <textarea
                          placeholder="Adicione uma nova anotação..."
                          onChange={event => setComment(event.target.value)}
                          value={comment}
                        />
                        <button disabled={!comment} onClick={handlePostComment}>
                          Salvar anotação
                        </button>
                      </div>
                    </main>
                  </Commentmain>
                  <MyCommentsContainer
                    ref={parentCommentsList}
                    id="list-comments"
                  >
                    {currentInfos?.notes?.map((comment, index) => (
                      <Fragment key={index}>
                        <header>
                          <h5>
                            {format(
                              parseISO(comment.created_at),
                              "dd'/'MM'/'Y",
                              { locale: ptBR },
                            )}{' '}
                            {}
                          </h5>
                          <TooltipAdapter
                            alt="Excluir anotação"
                            place="top"
                            cursor="pointer"
                            onClick={() => handleDeleteComment(comment.id)}
                          >
                            <TrashIcon>
                              <Trash weight="duotone" />
                            </TrashIcon>
                          </TooltipAdapter>
                        </header>
                        <p>{comment?.content}</p>
                        <Hr />
                      </Fragment>
                    ))}
                  </MyCommentsContainer>
                </Center>
              </CommentsContainer>
            </FullWidthMain>
          ) : (
            <FullWidthMain>
              <Center>
                <BreadcrumbsContainer onClick={() => setIsExercise(false)}>
                  <CaretLeft weight="bold" size={16} />
                  <button>Voltar para a aula</button>
                </BreadcrumbsContainer>
                {currentLesson && (
                  <ExerciseModule lessson_id={currentLesson.id} />
                )}
              </Center>
            </FullWidthMain>
          )}
        </FullWidthMainContent>
      </FullWidthContainer>
    </>
  );
};

export default PlanoDeEstudosAula;
